<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt-6"
      :class="{'page-loading': apiStatus === 'loading'}"
    >
      <div class="alert alert-success alert-dismissible" v-if="postStatus === 'success'">
        Poprawnie zapisano dane
      </div>
      <div class="row">
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">
              <h3>Dane kategorii</h3>
            </div>
            <div class="card-body">
              <base-input
                type="text"
                v-model="categoryName"
                label="Nazwa kategorii"
                placeholder="Wprowadź nazwę kategorii"
              />
              <div class="row">
                <div class="col-6">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <span>Aktywna</span>
                    <base-switch v-model="isCategoryActive" label="Aktywna" />
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <span>Wewnętrzna</span>
                    <base-switch v-model="isCategoryInternal" label="Aktywna" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
                <div class="row">
                  <div class="col-6">
                    <base-input label="Rabat dla kategorii" type="number" v-model="discount"/>
                  </div>
                  <div class="col-6">
                    <base-input label="Pozycja" type="number" v-model="position"/>
                  </div>
                </div>
            </div>
          </div>
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">SEO</h3>
              </div>
            </div>
            <base-input label="Meta title" v-model="seoTitle" placeholder="Wprowadź Meta Title">
            </base-input>
            <base-input label="Meta description">
                <textarea
                  class="form-control mb-4"
                  placeholder="Wprowadź Meta Description"
                  rows="4"
                  v-model="seoDesc"
                ></textarea>
            </base-input>
            <base-input label="Opis H1">
                <textarea
                  class="form-control mb-4"
                  placeholder="Wprowadź pole SEO dla tagu h1 w tej kategorii"
                  rows="15"
                  v-model="seoH1"
                ></textarea>
            </base-input>
          </card>
        </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header"><h3>Opisy</h3></div>
            <div class="card-body">
              <base-input label="Krótki opis">
                <textarea
                  class="form-control mb-4"
                  placeholder="Wpisz krótki opis kategorii"
                  rows="4"
                  v-model="shortDescription"
                ></textarea>
              </base-input>
              <base-input label="Długi opis">
                <textarea
                  class="form-control mb-4"
                  placeholder="Wpisz długi opis kategorii"
                  rows="10"
                  v-model="longDescription"
                ></textarea>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right">
        <div class="btn btn-outline-primary">
          Anuluj
        </div>
        <div class="btn btn-primary" @click="handleSubmitForm">
          Zapisz zmiany
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import { mapState, mapActions, mapMutations } from 'vuex';

const VuexMappingFactory = (computedName, vuexMapping, nullishFallback = '' ) => {
  const getValue = (obj, path) => {
    if (!path) return obj;
    const properties = path.split('.');
    return getValue(obj[properties.shift()], properties.join('.'))
  }
  try {
    return {
      [computedName]: {
        get() {
          return getValue(this.categoryInformation, vuexMapping) ?? nullishFallback;
        },
        set(newVal) {
          this.updateCategoryField({field: vuexMapping, value: newVal});
        }
      }
    }
  } catch (e) {
    return false;
  }
}

export default {
  name: 'ProductCategoryView',
  components: {
    BreadcrumbHeader,
  },
  data: () => ({
    isActive: false
  }),
  computed: {
    ...mapState('ProductCategoryViewModule', ['categoryInformation', 'apiStatus', 'postStatus' ]),
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Kategorie', link: {name: 'product-category-list'}},
        {title: `Kategoria ID: ${this.$route.params.id}`}
      ];
    },
    ...VuexMappingFactory('categoryName', 'name', ''),
    ...VuexMappingFactory('isCategoryActive', 'active', false),
    ...VuexMappingFactory('isCategoryInternal', 'internal', false),
    ...VuexMappingFactory('discount', 'discount', 0),
    ...VuexMappingFactory('position', 'position', 0),
    ...VuexMappingFactory('seo', 'seo', 0),
    shortDescription: {
      get() {
        return this.categoryInformation?.['description']?.short ?? '';
      },
      set(newVal) {
        this.updateCategoryField({field: 'description', value: {short: newVal}} );
      }
    },
    longDescription: {
      get() {
        return this.categoryInformation?.['description']?.long ?? '';
      },
      set(newVal) {
        this.updateCategoryField({field: 'description', value: {long: newVal}} );
      }
    },
    seoTitle: {
      get() {
        return this.categoryInformation.seo?.title ?? '';
      },
      set(newVal) {
        this.updateCategoryField({field: 'seo', value: {title: newVal}} );
      }
    },
    seoDesc: {
      get() {
        return this.categoryInformation.seo?.description ?? '';
      },
      set(newVal) {
        this.updateCategoryField({field: 'seo', value: {description: newVal}} );
      }
    },
    seoH1: {
      get() {
        return this.categoryInformation.seo?.h1 ?? '';
      },
      set(newVal) {
        this.updateCategoryField({field: 'seo', value: {h1: newVal}} );
      }
    },
  },
  methods: {
    ...mapMutations('ProductCategoryViewModule', ['updateCategoryField', 'resetCategoryInfo']),
    ...mapActions('ProductCategoryViewModule', ['fetchCategoryInformation', 'submitForm']),
    async handleSubmitForm() {
      const handleCategoryId = this.$route?.params?.id ? `/${this.$route.params.id}` : '';
      await this.submitForm(handleCategoryId);
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.fetchCategoryInformation(this.$route.params.id);
    }
  },
  beforeDestroy() {
    this.resetCategoryInfo();
  },
  watch: {
    $route(to, from) {
      if ( to !== from ) {
        this.resetCategoryInfo();
      }
    }
  }
};
</script>
<style>
.page-loading {
  opacity: 0.5;
}
</style>
